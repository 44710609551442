import cx from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { ChevronDown, Menu } from "react-feather";
import { useSelector } from "react-redux";
import { overrideTailwindClasses as override } from "tailwind-override";

import LogoUrl from "../../../public/images/logo.svg";
import { getUser } from "../../../redux/slices/userSlice";
import { User } from "../../../types/types";
import Button from "../../atoms/Button/Button";

const LoginOrGoToAppButton = ({ user }) => (
  <div className="block lg:flex items-center justify-center w-auto lg:w-full rounded-md  px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500">
    {user && user.email ? (
      <a href="https://app.shopirise.com">
        <Button size="s">
          <span className="whitespace-nowrap">Go to app</span>
          <span className="lg:hidden">{user.displayName}</span>
        </Button>
      </a>
    ) : (
      <>
        <a href={`${process.env.NEXT_PUBLIC_SHOPIRISE_APP_URL}/login`}>
          <Button
            className="text-gray-800"
            bgColor="bg-white hover:bg-gray-50 hover:text-white"
            size="s"
          >
            <span className="whitespace-nowrap">Log in</span>
          </Button>
        </a>
        <a href="https://app.shopirise.com/signup">
          <Button className="lg:ml-4 mt-4 lg:mt-0" size="s">
            <span className="whitespace-nowrap">Sign up</span>
          </Button>
        </a>
        <span className="lg:hidden">Log in</span>
      </>
    )}
  </div>
);

const ListItem = ({ children, href, className = "" }) => {
  const router = useRouter();
  const isActive = router.asPath === href;
  return (
    <Link href={href}>
      <a
        className={override(
          cx(
            "dark:text-white hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-md font-medium",
            className,
            {
              "text-gray-800": isActive,
              "dark:text-white": isActive,
              "hover:text-gray-800": true,
              "dark:hover:text-white": true,
              "text-gray-500": !isActive,
            }
          )
        )}
      >
        {children}
      </a>
    </Link>
  );
};

const ListItemDropdown = ({ children, href, label }) => {
  const router = useRouter();
  const isActive = router.asPath === href;
  return (
    <div className="group">
      <Link href={href}>
        <a
          className={cx(
            "dark:text-white hover:text-gray-800 dark:hover:text-white px-3 py-2 flex rounded-md text-md font-medium",
            {
              "text-gray-800": isActive,
              "dark:text-white": isActive,
              "hover:text-gray-800": true,
              "dark:hover:text-white": true,
              "text-gray-500": !isActive,
            }
          )}
        >
          {label}{" "}
          <div className="ml-1 flex items-center">
            <ChevronDown size={16} />
          </div>
        </a>
      </Link>
      <ul className="flex flex-col lg:absolute lg:hidden pt-1 lg:group-hover:block z-30">
        {children}
      </ul>
    </div>
  );
};

const NavItems = () => (
  <>
    <ListItemDropdown href="/products" label="Products">
      <div className="flex flex-col lg:rounded-md lg:shadow-md lg:w-80 ml-2">
        <ListItem href="/products/frequently-bought-together">
          Frequently Bought Together plugin
        </ListItem>

        <ListItem href="/products/recommendations">
          Recommendations plugin
        </ListItem>
      </div>
    </ListItemDropdown>
    <ListItem href="/pricing">Pricing</ListItem>
    <ListItem href="mailto:info@shopirise.com">Contact</ListItem>
  </>
);

const LandingNavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const user: User = useSelector(getUser);

  return (
    <nav className="bg-white dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex">
            <Link href="/">
              <a className="flex-shrink-0">
                <LogoUrl height={32} width={133} alt="Shopirise" />
              </a>
            </Link>
            <div className="hidden lg:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <NavItems />
              </div>
            </div>
          </div>
          <div className="hidden lg:block">
            <div className="ml-4 flex items-center lg:ml-6">
              <div className="ml-3 relative">
                <div className="relative inline-block text-left">
                  <div>
                    <LoginOrGoToAppButton user={user} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex lg:hidden">
            <button
              type="button"
              aria-label="Menu button"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-800 dark:text-white hover:text-gray-300 inline-flex items-center justify-center p-2 rounded-md focus:outline-none"
            >
              <Menu size={32} />
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="lg:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 flex flex-col">
            <NavItems />
            <LoginOrGoToAppButton user={user} />
          </div>
        </div>
      )}
    </nav>
  );
};

export default LandingNavBar;
