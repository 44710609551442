import Link from "next/link";
import React, { FC } from "react";
import { Facebook, Twitter } from "react-feather";

type FooterProps = {};

const Footer: FC<FooterProps> = () => (
  <footer className="px-3 py-8 bg-white dark:bg-gray-800 text-2 text-gray-500 dark:text-gray-200 transition-colors duration-200">
    <div className="flex flex-col">
      <div className="md:hidden mt-7 mx-auto w-11 h-px rounded-full" />
      <div className="mt-4 md:mt-0 flex flex-col md:flex-row">
        <nav className="flex-1 flex flex-col items-center justify-center md:items-end md:border-r border-gray-100 md:pr-5">
          <Link href="/products">
            <a
              aria-current="page"
              className="hover:text-gray-700 dark:hover:text-white py-3"
            >
              Products
            </a>
          </Link>
          <Link href="/pricing">
            <a
              aria-current="page"
              className="hover:text-gray-700 dark:hover:text-white py-3"
            >
              Pricing
            </a>
          </Link>
          <Link href="/privacy">
            <a
              aria-current="page"
              className="hover:text-gray-700 dark:hover:text-white py-3"
            >
              Privacy Policy
            </a>
          </Link>
          <Link href="/tos">
            <a
              aria-current="page"
              className="hover:text-gray-700 dark:hover:text-white py-3"
            >
              Terms of Service
            </a>
          </Link>
          <a
            href="mailto:info@shopirise.com"
            target="_blank"
            aria-current="page"
            className="hover:text-gray-700 dark:hover:text-white py-3"
            rel="noreferrer"
          >
            Contact
          </a>
        </nav>
        <div className="md:hidden mt-4 mx-auto w-11 h-px rounded-full" />
        <div className="mt-4 md:mt-0 flex-1 flex items-center justify-center md:border-r border-gray-100">
          <a
            className="hover:text-primary-gray-20"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/ShopirisePlugins/"
          >
            <span className="sr-only">Facebook page</span>
            <Facebook />
          </a>
          <a
            className="ml-4 hover:text-primary-gray-20"
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/Shopirise"
          >
            <span className="sr-only">Twitter page</span>
            <Twitter />
          </a>
        </div>
        <div className="md:hidden mt-4 mx-auto w-11 h-px rounded-full " />
        <div className="mt-7 md:mt-0 flex-1 flex flex-col items-center justify-center md:items-start md:pl-5">
          <span className="">© {new Date().getFullYear()}</span>
          <span className="mt-7 md:mt-1">Made with ❤️ in Finland</span>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
